import {
    AdminPanelSettings,
    Business,
    Dashboard,
    GroupSharp,
} from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Routes = [
    {
        text: "Dashboard",
        route: "dashboard",
        icon: <Dashboard style={{ color: "#000000" }} />,
    },
    {
        text: "Companies",
        route: "companies",
        icon: <Business style={{ color: "#000000" }} />,
    },
    {
        text: "Admins",
        route: "admins",
        icon: <AdminPanelSettings style={{ color: "#000000" }} />,
    },
];
