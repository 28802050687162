import Autocomplete from "@mui/material/Autocomplete";
import { Box, InputBase, Stack, TextField, Typography } from "@mui/material";
import MkAutoComplete from "../app/assets/theme/MkAutoComplete";
import MkDataPicker from "../app/assets/theme/MkDatePicker";
import { useState } from "react";
import InputFormComponent from "../app/components/Form/InputFormComponent";
import MenuPopover from "../app/assets/theme/MenuPopover";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: "top",
        },
        title: {
            display: true,
            text: "Most View of This Website",
        },
    },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
    labels,
    datasets: [
        {
            label: "Boy",
            data: labels.map(() =>
                faker.datatype.number({ min: 0, max: 1000 })
            ),
            backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
            label: "Girl",
            data: labels.map(() =>
                faker.datatype.number({ min: 0, max: 1000 })
            ),
            backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
    ],
};

function Dashboard() {
    const [value, setValue] = useState(null);
    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(null);
    };
    return (
        <>
            <Bar options={options} data={data} />
        </>
    );
}

export default Dashboard;
