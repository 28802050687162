import { Add, Close } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AlertBox from "../../../app/components/AlertBox/AlertBox";
import InputFormComponent from "../../../app/components/Form/InputFormComponent";
import { add_tandc } from "../../../features/company/TandcApi";

export const TandcAdd = () => {
    const [open, setOpen] = useState(false);
    const [description, setDescription] = useState("");
    const { campaignInfo } = useSelector((state) => state.campaignDetail);
    const [alertState, setAlertState] = useState();
    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAdd = (e) => {
        if (description === "") {
            setAlertState({
                open: true,
                vertical: "top",
                horizontal: "center",
                variant: "error",
                message: "Please Fill Description!",
            });
            setOpen(false);
            return;
        }
        e.preventDefault();
        const data = {
            description: description,
            campaign_id: campaignInfo.id,
            active: 0,
        };
        dispatch(add_tandc({ data: data }));
        setDescription("");
        setOpen(false);
    };

    return (
        <>
            <Button startIcon={<Add />} onClick={handleClickOpen}>
                Add
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                component="form"
                onSubmit={handleAdd}
            >
                <DialogContent>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <DialogContentText sx={{ padding: "10px 25px " }}>
                            Add Terms and Conditions
                        </DialogContentText>
                        <IconButton
                            onClick={handleClose}
                            size="large"
                            sx={{ margin: "20px 35px" }}
                        >
                            <Close fontSize="inherit" />
                        </IconButton>
                    </Stack>
                    <Box
                        sx={{
                            padding: "25px",
                            width: "800px",
                            height: "400px",
                        }}
                    >
                        <Stack
                            direction="column"
                            justifyContent="center"
                            sx={{ width: "100%" }}
                        >
                            <InputFormComponent
                                value={description}
                                onChange={(e) => {
                                    setDescription(e.currentTarget.value);
                                }}
                                lable="Description"
                                name="description"
                                placeholder="Enter Description"
                                focus={true}
                                required
                                multiline={true}
                                rows={15}
                            />
                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ padding: "15px 35px" }}>
                    <Button type="submit">Add</Button>
                </DialogActions>
            </Dialog>
            <AlertBox alertState={alertState} />
        </>
    );
};
