import PointerSVG from "../../app/assets/images/pointer.svg";
import Arrow from "../../app/assets/images/arrow2.png";

const CircleRing = ({ children, circleCount, style }) => {
    let circles = [];
    for (let i = -0; i < circleCount; i++)
        circles.push(
            <div
                className="circle"
                key={i}
                style={{
                    "--circle-index": i,
                    "--circle-color": i % 2 === 0 ? "white" : "#f5e137",
                    "--circle-count": circleCount,
                }}
            />
        );

    return (
        <div
            className="circle-container"
            style={{ "--ring-color": "black", ...style }}
        >
            {circles}
            <img src={Arrow} alt="Selected" className="circle-arrow" />
            <p id="spin-btn-1" className="spin">
                Spin
            </p>
            <div className="center">{children}</div>
        </div>
    );
};

export default CircleRing;
