import {Add} from '@mui/icons-material';
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    Input,
    Typography,
} from '@mui/material';
import {Box} from '@mui/system';
import React from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import AlertBox from '../../../app/components/AlertBox/AlertBox';
import InputFormComponent from '../../../app/components/Form/InputFormComponent';
import {addPromotion} from '../../../features/company/CampaignApi';
import generateColor from '../../../app/helper/generateColor';
import PlaceholderImage from '../../../app/assets/images/promotionPlaceholderImage.png';

export default function PromotionAdd() {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state).loginInfo;
    const {campaignInfo} = useSelector(
        (state) => state.campaignDetail
    );

    const {promotionList} = useSelector((state) => state.promotions);
    const [alertState, setAlertState] = useState();
    //for validation
    const [percent, setPercent] = useState(0);
    const [amount, setAmount] = useState(0);
    const [disablePercent, setDisablePercent] = useState(
        amount === 0 ? false : true
    );
    const [disableAmount, setDisableAmount] = useState(
        percent === 0 ? false : true
    );
    const [imageData, setImageData] = useState('#');

    useEffect(() => {
        setDisablePercent(amount === 0 ? false : true);
        setDisableAmount(percent === 0 ? false : true);
    }, [percent, amount]);

    const handleClose = () => {
        setOpen(false);
        setAmount(0);
        setPercent(0);
        setImageData('#');
    };

    const handleClickOpen = () => {
        setImageData(PlaceholderImage);
        setOpen(true);
    };

    const imageInputChange = (e) => {
        const [file] = e.target.files;
        if (file) {
            // setImageChange(true);
            setImageData(URL.createObjectURL(file));
        }
    };

    const handleAdd = (event) => {
        event.preventDefault();
        const color = generateColor();
        const formData = new FormData(event.currentTarget);
        formData.append('admin_id', user.admin.id);
        formData.append('campaign_id', campaignInfo.id);
        console.log(color);
        formData.append('bg_color', `#${color}`);
        if (!amount) {
            formData.delete('amount');
            formData.append('amount', 0);
        }
        if (!percent) {
            formData.delete('percent');
            formData.append('percent', 0);
        }
        if (
            formData.get('title') === '' ||
            formData.get('description') === ''
        ) {
            setAlertState({
                open: true,
                vertical: 'top',
                horizontal: 'right',
                variant: 'error',
                message: 'Please fill Title and Description!',
            });
            return;
        }
        if (promotionList.length >= 18) {
            setAlertState({
                open: true,
                vertical: 'top',
                horizontal: 'right',
                variant: 'error',
                message: 'Promotion Limited at 15!',
            });
            return;
        }
        dispatch(addPromotion({data: formData}));
        setOpen(false);
        setAmount(0);
        setPercent(0);
    };

    return (
        <>
            <IconButton
                onClick={handleClickOpen}
                sx={{
                    display: campaignInfo.finish === 1 ? 'none' : '',
                }}
            >
                <Add sx={{color: '#2152ff'}} />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                component="form"
                onSubmit={handleAdd}
            >
                <DialogContent>
                    <Box sx={{padding: '25px 10px'}}>
                        <Box>
                            <Typography variant="h4" mb={3}>
                                Add New Promotion
                            </Typography>
                            <Grid
                                container
                                rowSpacing={1}
                                columnSpacing={{
                                    xs: 1,
                                    sm: 2,
                                    md: 22,
                                }}
                            >
                                <Grid item xs={12} md={6}>
                                    <Box>
                                        <label htmlFor="contained-button-file">
                                            <Input
                                                accept="image/*"
                                                id="contained-button-file"
                                                type="file"
                                                name="image"
                                                sx={{
                                                    display: 'none',
                                                }}
                                                onChange={
                                                    imageInputChange
                                                }
                                            />
                                            <Avatar
                                                sx={{
                                                    width: '100%',
                                                    height: '200px',
                                                    cursor: 'pointer',
                                                }}
                                                variant="rounded"
                                                src={imageData}
                                            />
                                        </label>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={6}></Grid>
                                <Grid item xs={12} md={6}>
                                    <InputFormComponent
                                        lable="Title"
                                        name="title"
                                        placeholder="Enter Promotion Title"
                                        focus={true}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <InputFormComponent
                                        lable="description"
                                        name="description"
                                        placeholder="Enter Description"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <InputFormComponent
                                        inputProps={{min: 0}}
                                        defaultValue={0}
                                        type="number"
                                        onChange={(e) => {
                                            setPercent(
                                                Number(e.target.value)
                                            );
                                        }}
                                        lable="Percent"
                                        name="percent"
                                        placeholder="Enter Percent"
                                        readOnly={disablePercent}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputFormComponent
                                        inputProps={{min: 0}}
                                        defaultValue={0}
                                        type="number"
                                        onChange={(e) => {
                                            setAmount(
                                                Number(
                                                    e.currentTarget
                                                        .value
                                                )
                                            );
                                        }}
                                        lable="Amount"
                                        name="amount"
                                        placeholder="Enter Amount"
                                        readOnly={disableAmount}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputFormComponent
                                        inputProps={{min: 0}}
                                        type="number"
                                        defaultValue={0}
                                        lable="Expected Maximum"
                                        name="expected_maximum"
                                        placeholder="Enter Expected Maximum"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputFormComponent
                                        type="text"
                                        lable="Third Party Promotion ID"
                                        name="promotion_id"
                                        placeholder="Enter Third Party Promotion ID"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={{padding: '15px 35px'}}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Add</Button>
                </DialogActions>
            </Dialog>
            <AlertBox alertState={alertState} />
        </>
    );
}
