import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CompanyUserTable from "./CompanyUserTable";

function UserTabView({ setTabIndex }) {
    return (
        <>
            <CompanyUserTable setTabIndex={setTabIndex} />
        </>
    );
}

export default UserTabView;
