import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Input,
    InputLabel,
    Stack,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Add } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import CampaignInputs from "./CampaignInputs";
import { addCampaign } from "../../../features/company/CampaignApi";
import { format, parseISO } from "date-fns";
import PlaceholderImage from "../../../app/assets/images/placeholderImage.png";
import PlaceholderImageMobile from "../../../app/assets/images/proplaceholder.png";
import millisecondsToHours from "date-fns/millisecondsToHours";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import differenceInDays from "date-fns/differenceInDays";
import PlaceholderQRImage from "../../../app/assets/images/qr_placeholder.png";

function CampaignAdd() {
    const { companyDetail } = useSelector((state) => state.companyDetail);
    const [open, setOpen] = useState(false);
    const [check, setCheck] = useState(true);
    const dispatch = useDispatch();
    const [imageData, setImageData] = useState("#");
    const [imageDataMobile, setImageDataMobile] = useState("#");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [campaignType, setCampaignType] = useState(0);
    const [imageQRData, setImageQRData] = useState("#");
    const [checkShortUrlStatus, setCheckShortUrlStatus] = useState(false);
    const [bannerStatus, setBannerStatus] = useState(false);

    const handleClickOpen = () => {
        setCheck(true);
        setOpen(true);
        setImageData(PlaceholderImage);
        setImageQRData(PlaceholderQRImage);
        setImageDataMobile(PlaceholderImageMobile);
    };

    const handleClose = () => {
        setCheck(true);
        setOpen(false);
        setImageData("#");
        setImageQRData("#");
        setImageDataMobile("#");
        setCampaignType(0);
        setCheckShortUrlStatus(false);
        setBannerStatus(false);
        setStartDate(null);
        setEndDate(null);
    };

    const handleAdd = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        formData.append("company_id", companyDetail.id);

        const start = format(new Date(startDate), "yyyy-MM-dd HH:mm:ss");
        const end = format(
            new Date(endDate === null ? startDate : endDate),
            "yyyy-MM-dd HH:mm:ss"
        );
        // const currentDate = new Date(format(new Date(), "yyyy-MM-dd 00:00:00"));
        // const remainingTime = differenceInDays(endDate, currentDate) * 3600000;
        // formData.append("remaining_time", remainingTime);
        //change date format from datepick and Post form data to backend
        formData.append("start", start);
        formData.append("end", end);
        formData.append("disable", 1);
        formData.append("finish", 0);
        formData.append("short_url_status", checkShortUrlStatus ? 1 : 0);
        formData.append("banner_status", bannerStatus ? 1 : 0);
        for (var pair of formData.entries()) {
            console.log(pair[0] + ", " + pair[1]);
        }
        dispatch(addCampaign(formData));
        //close the model box
        handleClose();
    };

    const imageInputChange = (e) => {
        const [file] = e.target.files;
        if (file) {
            // setImageChange(true);
            setImageData(URL.createObjectURL(file));
        }
    };
    const imageInputChangeMobile = (e) => {
        const [file] = e.target.files;
        if (file) {
            setImageDataMobile(URL.createObjectURL(file));
        }
    };

    // console.log(millisecondsToHours(46800000));

    return (
        <>
            <Button startIcon={<Add />} onClick={handleClickOpen}>
                Add
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                component="form"
                onSubmit={handleAdd}
            >
                <DialogContent>
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                    >
                        <Box sx={{ padding: "20px 35px " }}>
                            <Typography variant="h2">Add Campaign</Typography>
                        </Box>
                    </Stack>
                    <Box sx={{ width: "100%", padding: "0px 35px" }}>
                        <InputLabel
                            htmlFor="banner_image"
                            sx={{ mb: 1, fontSize: "14.5px" }}
                        >
                            Banner
                        </InputLabel>
                        <label htmlFor="contained-button-file">
                            <Input
                                accept="image/*"
                                id="contained-button-file"
                                type="file"
                                name="banner_image"
                                sx={{
                                    display: "none",
                                }}
                                onChange={imageInputChange}
                            />
                            <Avatar
                                sx={{
                                    width: "100%",
                                    height: 200,
                                    cursor: "pointer",
                                }}
                                variant="rounded"
                                src={imageData}
                            />
                        </label>
                    </Box>

                    <Box sx={{ width: "100%", padding: "15px 35px" }}>
                        <InputLabel
                            htmlFor="mobile_banner_image"
                            sx={{ mb: 1, fontSize: "14.5px" }}
                        >
                            Mobile Banner
                        </InputLabel>
                        <label htmlFor="contained-mobile-file">
                            <Input
                                accept="image/*"
                                id="contained-mobile-file"
                                type="file"
                                name="mobile_banner_image"
                                sx={{
                                    display: "none",
                                }}
                                onChange={imageInputChangeMobile}
                            />
                            <Avatar
                                sx={{
                                    width: "100%",
                                    height: 400,
                                    cursor: "pointer",
                                }}
                                variant="rounded"
                                src={imageDataMobile}
                            />
                        </label>
                    </Box>
                    <CampaignInputs
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        campaignType={campaignType}
                        setCampaignType={setCampaignType}
                        imageQRData={imageQRData}
                        setImageQRData={setImageQRData}
                        checkShortUrlStatus={checkShortUrlStatus}
                        setCheckShortUrlStatus={setCheckShortUrlStatus}
                        bannerStatus={bannerStatus}
                        setBannerStatus={setBannerStatus}
                    />
                </DialogContent>
                <DialogActions sx={{ padding: "15px 35px" }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Add</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CampaignAdd;
