import { useEffect, useState } from "react";

import {
    Avatar,
    Box,
    Button,
    Divider,
    IconButton,
    Popover,
    Stack,
    Typography,
} from "@mui/material";
import { NotificationsRounded } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import User1 from "../../assets/images/profile.jpeg";
import { Link } from "react-router-dom";
import LogoutAlert from "../AlertBox/LogoutAlert";
import { useSelector } from "react-redux";
import { getAdminList } from "../../../features/admin/AdminApi";
import { useDispatch } from "react-redux";
import { imageApi } from "../../../app/hooks";

function RightNav() {
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();

    const user = useSelector((state) => state.loginInfo);

    const { adminList } = useSelector((state) => state.adminList);

    const [admin, setAdmin] = useState(
        adminList.find((ad) => ad.id === user.admin.id)
    );

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [openAlert, setOpenAlert] = useState(false);

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    useEffect(() => {
        dispatch(getAdminList());
    }, []);

    useEffect(() => {
        setAdmin(adminList.find((ad) => ad.id === user.admin.id));
    }, [adminList]);

    return (
        <>
            <Stack
                direction="row"
                spacing={2}
                divider={<Divider flexItem orientation="vertical" />}
            >
                <IconButton>
                    <NotificationsRounded />
                </IconButton>
                <>
                    {/* <Avatar src={User1} onClick={handleClick} /> */}
                    <Avatar
                        src={`${imageApi}/${admin?.profile}`}
                        onClick={handleClick}
                    >
                        {admin?.name?.slice(0, 1)}
                    </Avatar>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        sx={{
                            mt: "10px",
                            boxShadow: "0px 0px 50px 0px rgb(82 63 105 / 15%)",
                        }}
                    >
                        <Stack
                            divider={
                                <Divider flexItem orientation="horizontal" />
                            }
                        >
                            <Box
                                sx={{
                                    color: "#333",
                                    padding: "0.75rem 3rem 0.75rem 1rem",
                                }}
                            >
                                <Typography sx={{ fontSize: "1rem" }}>
                                    {`${admin?.name}`}
                                </Typography>
                                <Typography
                                    sx={{ fontSize: "0.75rem", color: "#333" }}
                                >
                                    {admin?.email}
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    padding: "1rem",
                                }}
                            >
                                {/* <Link to="/dashboard">
                                    <Typography
                                        sx={{ color: "#333", fontSize: "1rem" }}
                                    >
                                        Setting
                                    </Typography>
                                </Link> */}
                                <Link
                                    to="/profile"
                                    onClick={() => handleClose()}
                                >
                                    <Stack direction="row" spacing={1}>
                                        <AccountCircleIcon
                                            sx={{
                                                color: "#333",
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                color: "#333",
                                                fontSize: "1rem",
                                            }}
                                        >
                                            Profile
                                        </Typography>
                                    </Stack>
                                </Link>
                            </Box>
                            <Box sx={{ padding: "1rem" }}>
                                <Typography
                                    onClick={() => {
                                        setAnchorEl(null);
                                        //open logout alert
                                        setOpenAlert(true);
                                    }}
                                    sx={{
                                        color: "#333",
                                        fontSize: "1rem",
                                        cursor: "pointer",
                                    }}
                                >
                                    Logout
                                </Typography>
                            </Box>
                        </Stack>
                    </Popover>
                </>
            </Stack>
            <LogoutAlert openAlert={openAlert} setOpenAlert={setOpenAlert} />
        </>
    );
}

export default RightNav;
