import { Box } from "@mui/material";
import React from "react";
import TandcTable from "./TandcTable";

export default function TandcInfo({ tabIndex }) {
    return (
        <Box sx={{ mt: 5 }}>
            <TandcTable tabIndex={tabIndex} />
        </Box>
    );
}
