import { Avatar, TableCell, TableRow, Typography } from "@mui/material";
import MkChip from "../../app/assets/theme/MkChip";
import { Link } from "react-router-dom";
import EditAdmin from "./EditAdmin";
import { useDispatch } from "react-redux";
import { statusAdmin } from "../../features/admin/AdminApi";
import { imageApi } from "../../app/hooks";

export default function AdminLists({ item }) {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(statusAdmin({ id: item.id }));
    };

    return (
        <TableRow>
            <TableCell>
                <Avatar
                    sx={{
                        width: 40,
                        height: 40,
                        cursor: "pointer",
                    }}
                    variant="rounded"
                    alt={item.name}
                    src={`${imageApi}/${item.profile}`}
                />
            </TableCell>
            <TableCell align="right">
                <Typography
                    variant="caption"
                    component="div"
                    sx={{
                        textAlign: "left",
                    }}
                >
                    {item.name}
                </Typography>
            </TableCell>
            <TableCell>{item.email}</TableCell>
            <TableCell>
                {item.last_login !== null ? item.last_login : "N/A"}
            </TableCell>
            <TableCell align="right">
                <div onClick={handleClick}>
                    <MkChip active={item.active} />
                </div>
            </TableCell>
            <TableCell align="right">
                <EditAdmin item={item} />
            </TableCell>
        </TableRow>
    );
}
