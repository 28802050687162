import { Box, IconButton, Typography } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import { useSelector } from "react-redux";
import PrintIcon from "@mui/icons-material/Print";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { QRCode } from "react-qrcode-logo";
import { imageApi } from "../../../app/hooks";
import { format } from "date-fns";

export const QRCodeInfo = () => {
    const { campaignInfo } = useSelector((state) => state.campaignDetail);
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <Box sx={{ mt: 5, height: "100vh" }}>
            <Box sx={{ width: "100%", textAlign: "right" }}>
                <IconButton sx={{ color: "#1e2532" }} onClick={handlePrint}>
                    <PrintIcon sx={{ fontSize: 50 }} />
                </IconButton>
            </Box>
            <Box
                width={800}
                ref={componentRef}
                sx={{
                    margin: "20px auto",
                }}
            >
                <Typography
                    variant="h1"
                    sx={{
                        mb: 2,
                        fontSize: "30px",
                        fontWeight: 700,
                        textAlign: "center",
                    }}
                >
                    {campaignInfo?.event_title}
                </Typography>
                <Typography variant="h2" sx={{ mb: 5, textAlign: "center" }}>
                    {format(new Date(campaignInfo?.start), "MM/dd/yyyy")} ~{" "}
                    {format(new Date(campaignInfo?.end), "MM/dd/yyyy")}
                </Typography>
                <Typography
                    variant="h1"
                    sx={{
                        mb: 4,
                        fontSize: "20px",
                        fontWeight: 400,
                        padding: "0 10px",
                        textAlign: "center",
                    }}
                >
                    {campaignInfo?.qr_caption}
                </Typography>
                <Box sx={{ textAlign: "center" }}>
                    <QRCode
                        value={
                            campaignInfo?.short_url
                                ? campaignInfo?.short_url
                                : `https://mmspin.com/ac/${campaignInfo?.api_id}`
                        }
                        logoWidth={80}
                        logoImage={`${imageApi}/${campaignInfo?.qr_logo}`}
                        size={350}
                    />
                </Box>
                <Typography variant="h1" sx={{ mt: 5, textAlign: "center" }}>
                    {campaignInfo?.short_url
                        ? campaignInfo?.short_url
                        : `https://mmspin.com/ac/${campaignInfo?.api_id}`}
                </Typography>
            </Box>
        </Box>
    );
};
