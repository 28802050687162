import { useState } from "react";
import { Box, Grid, Input } from "@mui/material";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import MkSwitch from "../../../app/assets/theme/MkSwitch";
import InputFormComponent from "../../../app/components/Form/InputFormComponent";
import { useSelector } from "react-redux";
import MkAutoComplete from "../../../app/assets/theme/MkAutoComplete";

const labelTheme = createTheme({
    components: {
        MuiFormControlLabel: {
            label: {
                fontSize: "14px",
            },
        },
    },
});

function AddUserInputs({
    inputValues = null,
    handleCheck,
    checked,
    setRoleID,
    currentEmail,
}) {
    const theme = useTheme();
    const { user } = useSelector((state) => state.companyUser);
    const { roles } = useSelector((state) => state.companyUserRole);
    const [role, setRole] = useState("");
    //get company user search by email
    const { userByEmail } = useSelector((state) => state.companyUser);

    const [firstNameChange, setFristNameChange] = useState(
        userByEmail?.user?.first_name ?? ""
    );
    const [lastNameChange, setLastNameChange] = useState(
        userByEmail?.user?.last_name ?? ""
    );
    const [emailChange, setEmailChange] = useState(
        userByEmail?.user?.email ?? currentEmail
    );

    return (
        <Box sx={{ padding: "35px", width: "800px", height: "400px" }}>
            <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 12 }}
            >
                <Grid item xs={12} md={6}>
                    <InputFormComponent
                        value={firstNameChange}
                        onChange={(e) =>
                            setFristNameChange(e.currentTarget.value)
                        }
                        lable="First Name"
                        name="first_name"
                        placeholder="Enter Your User First Name"
                        focus={true}
                        required
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputFormComponent
                        value={lastNameChange}
                        onChange={(e) =>
                            setLastNameChange(e.currentTarget.value)
                        }
                        lable="Last Name"
                        name="last_name"
                        placeholder="Enter Your User Last Name"
                        required
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputFormComponent
                        type="email"
                        value={emailChange}
                        onChange={(e) => setEmailChange(e.currentTarget.value)}
                        lable="Email"
                        name="email"
                        placeholder="Enter Your User Email"
                        required
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputFormComponent
                        type="password"
                        // value={nameChange}
                        // onChange={(e) => setNameChange(e.currentTarget.value)}
                        lable="Password"
                        name="password"
                        placeholder="Enter Your User Passsword"
                        required
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <MkAutoComplete
                        options={roles}
                        placeholder="Select Authority Role"
                        sx={{ mb: "10px" }}
                        name="role_id"
                        onChange={(event, newValue) => {
                            setRole(newValue);
                            setRoleID(newValue.id);
                        }}
                        value={role}
                    />
                </Grid>

                <Grid item xs={12} md={6} sx={{ alignSelf: "center" }}>
                    <ThemeProvider theme={labelTheme}>
                        <MkSwitch
                            switchColor={theme.palette.info.main}
                            label="Active"
                            checked={checked}
                            onChange={handleCheck}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
        </Box>
    );
}

export default AddUserInputs;
