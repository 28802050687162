import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard.jsx";
import Layout from "../app/components/layout/Layout.jsx";
import Admin from "../pages/Admin.jsx";
import CompanyDetail from "../components/company/CompanyDetail.jsx";
import Company from "../pages/Company.jsx";
import Profile from "../pages/Profile.jsx";

function PrivateRoute() {
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="admins" element={<Admin />} />
                <Route path="profile" element={<Profile />} />
                <Route path="companies">
                    <Route path=":name" element={<CompanyDetail />} />
                    <Route index element={<Company />} />
                </Route>
            </Route>
            <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
    );
}

export default PrivateRoute;
