import { createSlice, current } from "@reduxjs/toolkit";
import {
    addCompanyList,
    editCompany,
    editCompanyList,
    getCompanyDetail,
    getCompanyList,
    getCompanyUserDetail,
    statusCompany,
    getCompanyUserByEmail,
    getCompanyUserRole,
    addCompanyUser,
    editCompanyUserDetail,
    statusCompanyUser,
} from "./CompanyApi";

const companyListInitial = {
    companyList: [],
    companyPagination: null,
};

export const CompanyList = createSlice({
    name: "getCompanyList",
    initialState: companyListInitial,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCompanyList.fulfilled, (state, { payload }) => {
                const { data, ...rest } = payload;
                state.companyList = data;
                state.companyPagination = rest;
            })
            .addCase(addCompanyList.pending, (state) => {})
            .addCase(addCompanyList.fulfilled, (state, { payload }) => {
                if (
                    state.companyPagination.current_page ===
                    state.companyPagination.last_page
                ) {
                    state.companyList.push(payload.company);
                }
            })
            .addCase(statusCompany.fulfilled, (state, { payload }) => {
                state.companyList = state.companyList.map((company) => {
                    if (company.id === payload.company.id) {
                        return {
                            ...company,
                            active: payload.company.active ? 1 : 0,
                        };
                    } else {
                        return { ...company };
                    }
                });
            });
    },
});

export const companyList = CompanyList.reducer;

const companyDetailInitial = {
    companyDetail: {},
    loading: true,
};

export const CompanyDetail = createSlice({
    name: "CompanyDetail",
    initialState: { ...companyDetailInitial },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCompanyDetail.pending, (state, { payload }) => {
                state.loading = true;
                //pending process remove pervious state company detail
                state.companyDetail = {};
            })
            .addCase(getCompanyDetail.fulfilled, (state, { payload }) => {
                const { company } = payload;
                state.loading = false;
                state.companyDetail = company;
            })
            .addCase(editCompany.fulfilled, (state, { payload }) => {
                state.companyDetail = payload.company;
            });
    },
});

export const companyDetail = CompanyDetail.reducer;

const initialCompanyUser = {
    usersList: [],
    loading: true,
    userByEmail: null,
};

export const companyUserList = createSlice({
    name: "companyUserList",
    initialState: { ...initialCompanyUser },
    reducers: {
        removeUserByEmail: (state, { payload }) => {
            state.userByEmail = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCompanyDetail.fulfilled, (state, { payload }) => {
                const { users } = payload;
                state.usersList = users;
            })
            .addCase(addCompanyUser.fulfilled, (state, { payload }) => {
                state.usersList.push(payload.user);
                state.userByEmail = null;
            })
            .addCase(getCompanyUserByEmail.pending, (state, { payload }) => {
                state.loading = true;
            })
            .addCase(getCompanyUserByEmail.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.userByEmail = payload;
            })
            .addCase(editCompanyUserDetail.fulfilled, (state, { payload }) => {
                state.usersList = state.usersList.map((user) => {
                    if (user.id === payload.user.id) {
                        return payload.user;
                    } else {
                        return user;
                    }
                });
            })
            .addCase(statusCompanyUser.fulfilled, (state, { payload }) => {
                state.usersList = state.usersList.map((user) => {
                    if (user.id == payload.user.id) {
                        return {
                            ...user,
                            status: payload.user.status,
                        };
                    } else {
                        return { ...user };
                    }
                });
            });
    },
});

export const companyUser = companyUserList.reducer;
export const { removeUserByEmail } = companyUserList.actions;

const initialCompanyRole = {
    roles: [],
};

const companyUserRoleList = createSlice({
    name: "companyUserRole",
    initialState: { ...initialCompanyRole },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCompanyUserRole.pending, (state, { payload }) => {})
            .addCase(getCompanyUserRole.fulfilled, (state, { payload }) => {
                const options = payload.map((data) => {
                    return {
                        label: data.description,
                        id: data.id,
                    };
                });
                state.roles = options;
            });
    },
});
export const companyUserRole = companyUserRoleList.reducer;
export const { getRoles } = companyUserRoleList.actions;

export const initialCompanyUserDetail = {
    userInfo: {},
};
export const companyUserDetail = createSlice({
    name: "companyUserDetail",
    initialState: { ...initialCompanyUserDetail },
    reducers: {
        initCompanyUserDetail: (state) => {
            return initialCompanyUserDetail;
        },
        changeUserInfo: (state, { payload }) => {
            state.userInfo = payload;
        },
    },
});
export const companyUserInfo = companyUserDetail.reducer;
export const companyUserDetailActions = companyUserDetail.actions;
