import { configureStore } from "@reduxjs/toolkit";
import { loginInfo } from "../features/login/LoginSlice";
import {
    companyList,
    companyDetail,
    companyUser,
    companyUserInfo,
    companyUserRole,
} from "../features/company/CompanySlice";
import { adminList } from "../features/admin/AdminSlice";
import { citiesData } from "../features/cites/CitiesSlice";
import { tandcList } from "../features/company/TandcSlice";
import { couponList } from "../features/company/CouponSlice";
import {
    campaignList,
    campaignDetail,
    promotions,
    promotionColorSlice,
} from "../features/company/CampaignSlice";
import { dragSlice } from "../components/company/dragNdrop/dragSlice";
import { csvexport } from "../features/csvexport/csvSlice";

const store = configureStore({
    reducer: {
        loginInfo,
        companyList,
        companyDetail,
        adminList,
        companyUser,
        companyUserInfo,
        companyUserRole,
        citiesData,
        campaignList,
        campaignDetail,
        promotions,
        dragSlice,
        tandcList,
        promotionColorSlice,
        couponList,
        csvexport,
    },
});

export default store;
