import React from 'react';
import {
    Avatar,
    Box,
    FormControlLabel,
    FormLabel,
    Grid,
    Input,
    InputLabel,
    Radio,
    RadioGroup,
} from '@mui/material';
import {
    useTheme,
    createTheme,
    ThemeProvider,
} from '@mui/material/styles';
import InputFormComponent from '../../../app/components/Form/InputFormComponent';
import MkDatePicker from '../../../app/assets/theme/MkDatePicker';
import MkSwitch from '../../../app/assets/theme/MkSwitch';

const labelTheme = createTheme({
    components: {
        MuiFormControlLabel: {
            label: {
                fontSize: '14px',
            },
        },
    },
});

function CampaignInputs({
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    campaignType,
    setCampaignType,
    imageQRData,
    setImageQRData,
    checkShortUrlStatus,
    setCheckShortUrlStatus,
    bannerStatus,
    setBannerStatus,
}) {
    const theme = useTheme();

    const imageQRInputChange = (e) => {
        const [file] = e.target.files;
        if (file) {
            // setImageChange(true);
            setImageQRData(URL.createObjectURL(file));
        }
    };

    return (
        <Box sx={{padding: '35px'}}>
            <Grid container rowSpacing={1} columnSpacing={3}>
                <Grid item xs={12}>
                    <InputFormComponent
                        lable="Event Name"
                        name="event_title"
                        placeholder="Event Name"
                        focus={true}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputFormComponent
                        placeholder="Enter Description"
                        lable="Description"
                        name="description"
                        multiline={true}
                        rows={4}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputFormComponent
                        lable="Callback Success URL"
                        name="callback_url"
                        placeholder="Callback URL"
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputFormComponent
                        lable="Callback Fail URL"
                        name="disagree_url"
                        placeholder="Callback Fail URL"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel sx={{mb: 1, fontSize: '1rem'}}>
                        Start Date
                    </InputLabel>
                    <MkDatePicker
                        minDate={new Date()}
                        name="start"
                        value={startDate}
                        onChange={(newValue) => {
                            setStartDate(newValue);
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputLabel sx={{mb: 1, fontSize: '1rem'}}>
                        End Date
                    </InputLabel>
                    <MkDatePicker
                        minDate={new Date()}
                        value={endDate}
                        onChange={(newValue) => {
                            setEndDate(newValue);
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel id="demo-radio-buttons-group-label">
                        Campaign Type
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={campaignType}
                        name="campaign_type"
                        onChange={(e) => {
                            setCampaignType(e.target.value);
                        }}
                    >
                        <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label="Regular"
                        />
                        <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="Auto Coupon"
                        />
                        <FormControlLabel
                            value={2}
                            control={<Radio />}
                            label="Embedded"
                        />
                        <FormControlLabel
                            value={3}
                            control={<Radio />}
                            label="Direct Campaign"
                        />
                    </RadioGroup>
                </Grid>
                <Grid
                    item
                    xs={6}
                    sx={{display: campaignType == 1 ? '' : 'none'}}
                >
                    <Box sx={{width: '100%'}}>
                        <InputLabel
                            htmlFor="qr_logo"
                            sx={{mb: 1, fontSize: '14.5px'}}
                        >
                            QR Logo
                        </InputLabel>
                        <label htmlFor="contained-file">
                            <Input
                                accept="image/*"
                                id="contained-file"
                                type="file"
                                name="qr_logo"
                                sx={{
                                    display: 'none',
                                }}
                                onChange={imageQRInputChange}
                            />
                            <Avatar
                                sx={{
                                    width: '30%',
                                    height: '30%',
                                    cursor: 'pointer',
                                }}
                                variant="rounded"
                                src={imageQRData}
                            />
                        </label>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{display: campaignType == 1 ? '' : 'none'}}
                >
                    <InputLabel
                        htmlFor="qr_caption"
                        sx={{mb: 1, fontSize: '14.5px'}}
                    >
                        QR Caption
                    </InputLabel>
                    <textarea
                        id="qr_caption"
                        lable="QR Caption"
                        name="qr_caption"
                        placeholder="QR Caption"
                        rows={4}
                    ></textarea>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        display: campaignType == 3 ? '' : 'none',
                    }}
                >
                    <InputFormComponent
                        lable="Callback Webview Channel"
                        name="webview_channel"
                        placeholder="Callback Webview Channel"
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                        display:
                            campaignType == 1 || campaignType == 3
                                ? ''
                                : 'none',
                    }}
                >
                    <InputLabel
                        htmlFor="status"
                        sx={{mb: 1, fontSize: '14.5px'}}
                    >
                        Short URL Generate
                    </InputLabel>
                    <ThemeProvider theme={labelTheme}>
                        <MkSwitch
                            id="status"
                            switchColor={theme.palette.info.main}
                            checked={checkShortUrlStatus}
                            onChange={(e) =>
                                setCheckShortUrlStatus(
                                    e.target.checked
                                )
                            }
                            inputProps={{
                                'aria-label': 'controlled',
                            }}
                        />
                    </ThemeProvider>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{display: campaignType == 2 ? '' : 'none'}}
                >
                    <InputLabel
                        htmlFor="status"
                        sx={{mb: 1, fontSize: '14.5px'}}
                    >
                        Campaign Banner Image Show Hide
                    </InputLabel>
                    <ThemeProvider theme={labelTheme}>
                        <MkSwitch
                            id="status"
                            switchColor={theme.palette.info.main}
                            checked={bannerStatus}
                            onChange={(e) =>
                                setBannerStatus(e.target.checked)
                            }
                            inputProps={{
                                'aria-label': 'controlled',
                            }}
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
        </Box>
    );
}

export default CampaignInputs;
