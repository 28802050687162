import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { reloadHandler } from "./features/login/LoginSlice";
import Login from "./pages/Login";
import PrivateRoute from "./routes/PrivateRoute";

function App() {
    const { token } = useSelector((state) => state.loginInfo);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(reloadHandler());
    }, []);

    return (
        <Routes>
            {token ? (
                <>
                    <Route path="/*" element={<PrivateRoute />} />
                    <Route index element={<Navigate to="/dashboard" />} />
                </>
            ) : (
                <>
                    <Route path="/login" element={<Login />} />
                    <Route path="*" element={<Navigate to="/login" />} />
                </>
            )}
        </Routes>
    );
}

export default App;
