import { createAsyncThunk } from "@reduxjs/toolkit";
import useFetch, { usePostForm } from "../../app/hooks";

export const getCompanyList = createAsyncThunk(
    "getCompanyList/getCompanyList",
    async ({ page, limit }, { getState }) => {
        const { token } = getState().loginInfo;
        const result = useFetch({
            url: `companies?page=${page + 1}&limit=${limit}`,
            method: "GET",
            token: token,
        });
        return result;
    }
);

export const addCompanyList = createAsyncThunk(
    "getCompanyList/addCompanyList",
    async ({ data }, { getState }) => {
        const { token } = getState().loginInfo;
        const result = usePostForm({
            url: "companies",
            method: "POST",
            token: token,
            data: data,
        });
        return result;
    }
);

export const editCompany = createAsyncThunk(
    "getCompanyList/editCompany",
    async ({ data, id }, { getState }) => {
        const { token } = getState().loginInfo;
        const result = usePostForm({
            url: `companies/${id}?_method=PUT`,
            method: "POST",
            token: token,
            data: data,
        });
        return result;
    }
);

export const statusCompany = createAsyncThunk(
    "statusCompany/statusCompany",
    async ({ id }, { getState }) => {
        const { token } = getState().loginInfo;
        const result = useFetch({
            url: `companies/${id}`,
            method: "DELETE",
            token: token,
        });
        return result;
    }
);

export const getCompanyDetail = createAsyncThunk(
    "getCompanyList/editCompanyList",
    async ({ id }, { getState }) => {
        const { token } = getState().loginInfo;
        const result = useFetch({
            url: `companies/${id}`,
            method: "GET",
            token: token,
        });
        return result;
    }
);

export const addCompanyUser = createAsyncThunk(
    "addCompanyUser/addCompanyUser",
    async ({ data }, { getState }) => {
        const { token } = getState().loginInfo;
        const result = usePostForm({
            url: "users",
            method: "POST",
            token: token,
            data: data,
        });
        return result;
    }
);

export const getCompanyUserDetail = createAsyncThunk(
    "getCompanyUserDetail/getCompanyUserDetail",
    async ({ id }, { getState }) => {
        const { token } = getState().loginInfo;
        const result = useFetch({
            url: `users/${id}`,
            method: "GET",
            token: token,
        });
        return result;
    }
);

export const editCompanyUserDetail = createAsyncThunk(
    "editCompanyUser/editCompanyUser",
    async ({ id, data }, { getState }) => {
        const { token } = getState().loginInfo;
        const result = usePostForm({
            url: `users/${id}?_method=PUT`,
            method: "POST",
            token: token,
            data: data,
        });
        return result;
    }
);

export const getCompanyUserByEmail = createAsyncThunk(
    "getCompanyUserByEmail/getCompanyUserByEmail",
    async (data, { getState }) => {
        const { token } = getState().loginInfo;
        const result = useFetch({
            url: `users/getByEmail`,
            method: "POST",
            token: token,
            data: data,
        });
        return result;
    }
);

export const getCompanyUserRole = createAsyncThunk(
    "getCompanyUserRoles/getCompanyUserRoles",
    async (_, { getState }) => {
        const { token } = getState().loginInfo;
        const result = useFetch({
            url: `roles`,
            method: "GET",
            token: token,
        });
        return result;
    }
);

export const statusCompanyUser = createAsyncThunk(
    "statusCompanyUser/statusCompanyUser",
    async ({ id, company_id }, { getState }) => {
        const { token } = getState().loginInfo;
        const result = useFetch({
            url: `users/${id}?company_id=${company_id}`,
            method: "DELETE",
            token: token,
        });
        return result;
    }
);
