import React, { useEffect, useRef } from "react";
import {
    Box,
    Typography,
    Paper,
    Toolbar,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Button,
    Stack,
    InputBase,
    IconButton,
    Grid,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Add, ReadMore, Settings, TableRows } from "@mui/icons-material";
import InputFormComponent from "../../../app/components/Form/InputFormComponent";
import { useState } from "react";
import TandcTableitem from "./TandcTableItem";
import {
    useTheme,
    styled,
    ThemeProvider,
    createTheme,
} from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { reorder_tandc } from "../../../features/company/TandcApi";
import DragDrop from "../dragNdrop";
import {
    add_pretext,
    reorder_promotion,
} from "../../../features/company/CampaignApi";
import TandcEdit from "./TandcEdit";
import { onReorderTandc } from "../../../features/company/TandcSlice";
import { TandcAdd } from "./TandcAdd";

const MkToolbar = styled(Toolbar)(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {
        padding: "0px",
    },
}));

function TandcTable({ tabIndex }) {
    const theme = useTheme();
    const { tandcList } = useSelector((state) => state.tandcList);
    const { campaignInfo } = useSelector((state) => state.campaignDetail);
    const [pretext, setPretext] = useState("");
    const dispatch = useDispatch();

    const handleAddPretext = () => {
        const data = {
            pretext: pretext,
        };
        dispatch(add_pretext({ id: campaignInfo.id, data: data }));
        setPretext("");
    };

    // for drag and drop
    const { drag } = useSelector((state) => state.dragSlice);
    const [accept, setAccept] = useState(false);
    const dragEnd = () => {
        if (drag?.source) {
            const postData = {
                campaign_id: campaignInfo.id,
                terms_and_condition_id: drag.item.id,
                source_sorting_order: drag.source.index,
                destination_sorting_order: drag.destination.index,
            };
            dispatch(reorder_tandc({ data: postData }));
        }
    };
    useEffect(() => {
        if (drag?.source) {
            setAccept(true);
        } else {
            setAccept(false);
        }
    }, [drag]);

    return (
        <Box component={Paper}>
            <Box sx={{ padding: "20px" }}>
                <MkToolbar sx={{ padding: 0 }}>
                    <Typography
                        sx={{ flex: "1 1 100%" }}
                        variant="subtitle1"
                        component="div"
                    >
                        Terms and Conditions
                    </Typography>
                    <TandcAdd />
                </MkToolbar>
                <Box
                    sx={{
                        marginBottom: "20px",
                        display: campaignInfo.finish === 1 ? "none" : " ",
                    }}
                >
                    <Box textAlign="right">
                        <InputFormComponent
                            placeholder="Enter Pretext"
                            lable=""
                            name="pretext"
                            value={pretext ?? campaignInfo.tnc_pretext}
                            onChange={(e) => {
                                setPretext(e.target.value);
                            }}
                            multiline={true}
                            rows={4}
                            onBlur={handleAddPretext}
                        />
                        {/* <MkButton
                            mkcolor={`linear-gradient(310deg, ${theme.palette.gradientDark.main}, ${theme.palette.gradientDark.light})`}
                            size="small"
                            onClick={handleAddPretext}
                            sx={{ marginTop: "10px" }}
                        >
                            Save
                        </MkButton> */}
                    </Box>
                </Box>
                <Box sx={{ width: "100%", height: "calc(90vh - 45px)", mt: 2 }}>
                    <DragDrop
                        list={tandcList}
                        accept={accept}
                        dragEnd={dragEnd}
                        listChangeSlice={onReorderTandc}
                        tabIndex={tabIndex}
                    >
                        {({ listItem: listItem }) => (
                            <TandcEdit item={listItem} />
                        )}
                    </DragDrop>
                </Box>
            </Box>
        </Box>
    );
}

export default TandcTable;
