import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Box,
    Input,
    Stack,
    Avatar,
} from "@mui/material";
import imageLogo from "../../app/assets/images/ImageIcon.png";
import { editCompany } from "../../features/company/CompanyApi";
import CompanyInputs from "./CompanyInputs";
import { useSelector } from "react-redux";

export default function EditCompany({ open, setOpen, companyDetail }) {
    let { company_logo, active, ...rest } = companyDetail;
    const { cities } = useSelector((state) => state.citiesData);
    const [imageChange, setImageChange] = useState(false);
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(active ? true : false);
    const [imageData, setImageData] = useState("#");
    const [township, setTownship] = useState(
        cities.filter((city) => city.id == rest?.township_id)[0]
    );

    const handleClose = () => {
        setChecked(false);
        setOpen(false);
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    //Todo when close popup clear the data include switch value. upload image button should be fullwidth

    const handleAdd = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        formData.append("active", checked === true ? Number(1) : Number(0));
        formData.append("township_id", township.id);
        formData.append("city_id", township.city_id);
        formData.append("division_id", township.division_id);
        setOpen(false);
        if (!imageChange) {
            formData.delete("company_logo");
        }
        dispatch(editCompany({ data: formData, id: rest.id }));
    };

    //imageInput change
    const imageInputChange = (e) => {
        const [file] = e.target.files;
        if (file) {
            setImageChange(true);
            setImageData(URL.createObjectURL(file));
        }
    };

    useEffect(() => {
        setChecked(active ? true : false);
        setImageData(
            company_logo ? `https://mmspin.com/${company_logo}` : imageLogo
        );
        setImageChange(false);
        setTownship(cities.filter((city) => city.id == rest?.township_id)[0]);
    }, [active, open]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="md"
            component="form"
            onSubmit={handleAdd}
        >
            <DialogContent>
                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                >
                    <DialogContentText sx={{ padding: "20px 35px " }}>
                        Edit Company
                    </DialogContentText>
                    <Box>
                        <label htmlFor="contained-button-file">
                            <Input
                                accept="image/*"
                                id="contained-button-file"
                                type="file"
                                name="company_logo"
                                sx={{
                                    display: "none",
                                }}
                                onChange={imageInputChange}
                            />
                            <Avatar
                                sx={{
                                    width: 70,
                                    height: 70,
                                    cursor: "pointer",
                                }}
                                variant="rounded"
                                src={imageData}
                            />
                        </label>
                    </Box>
                </Stack>
                <CompanyInputs
                    handleCheck={handleChange}
                    checked={checked}
                    inputValues={rest}
                    setTownship={setTownship}
                />
            </DialogContent>
            <DialogActions sx={{ padding: "15px 35px" }}>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit">Update</Button>
            </DialogActions>
        </Dialog>
    );
}
