import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import CircleRing from "./CircleRing";
import "./wheel.css";

import { imageApi } from "../../app/hooks";

const Wheel = () => {
    const { promotionList } = useSelector((state) => state.promotions);
    const { campaignWheel } = useSelector((state) => state.campaignDetail);
    const { selectedPromotion } = useSelector((state) => state.promotions);
    const { foreground, background, fontsize } = useSelector(
        (state) => state.promotionColorSlice
    );
    const { campaignInfo } = useSelector((state) => state.campaignDetail);

    if (promotionList.length === 0) {
        return (
            <Box>
                <Typography variant="h4">
                    There is no promotions in this campagin.
                </Typography>
            </Box>
        );
    }

    // let len = promotionList.length * count;
    // const res = Array(len)
    //     .fill()
    //     .map((_, idx) => promotionList[idx % promotionList.length]);

    //remove disabled promotions
    const filterPromotions = promotionList.filter((item) => {
        return item.disabled !== 1;
    });

    const wheelVars = {
        "--nb-item": filterPromotions.length,
        "--wheel-color": campaignWheel.bg_color,
        "--wheel-slice-spacing": promotionList.length > 13 ? "20px" : "30px",
        "--spin-btn-color": campaignWheel.spin_btn_color,
    };


    return (
        <CircleRing
            circleCount={15}
            style={{ "--ring-color": campaignWheel.border_color }}
        >
            {/* <div
                className="wheel-container"
                style={{
                    "--wheel-border-color": campaignWheel.border_color,
                    "--spin-btn-color": campaignWheel.spin_btn_color,
                }}
            >
              
            </div> */}
            <div className="wheel" style={wheelVars}>
                {filterPromotions?.map((item, index) => (
                    <div
                        className="wheel-item"
                        key={index}
                        style={{
                            "--item-nb": index,
                            "--slice-background":
                                selectedPromotion.id === item.id
                                    ? background == "#"
                                        ? filterPromotions[index].bg_color
                                        : background
                                    : filterPromotions[index].bg_color,
                            "--slice-font-color":
                                selectedPromotion.id === item.id
                                    ? foreground == "#"
                                        ? filterPromotions[index].font_color
                                        : foreground
                                    : filterPromotions[index].font_color,
                            "--slice-font-size": item.font_size,
                        }}
                    >
                        {item?.image && campaignInfo?.campaign_type != 3 ? (
                            <img
                                src={`${imageApi}/${item.image}`}
                                alt="prduct"
                                style={{ width: "90px" }}
                            />
                        ) : (
                            <span className="title"> {item.title}</span>
                        )}
                    </div>
                ))}
                <button></button>
            </div>
        </CircleRing>
    );
};

export default Wheel;
