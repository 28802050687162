import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box, IconButton, Paper, Stack, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { CopyAll } from "@mui/icons-material";
import { CopyBlock, dracula } from "react-code-blocks";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    "&:before": {
        display: "none",
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, .05)"
            : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function Embedded() {
    const { campaignInfo } = useSelector((state) => state.campaignDetail);
    const [expanded, setExpanded] = useState(null);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const syntax = `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Spin Page</title>
        <link rel="stylesheet" href="https://wailwinphyo.ml/mmspin.min.css">
    </head>
    <body>
        <div id="main" data-api-key="eda2bxxx-xxxx-xxx-xxxx-xxxxxxxxxxxx"  data-campaign-api-id="547xxx-xxxx-xxxx-xxxx-xxxxxxxxxxx"></div>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>
        <script src="https://wailwinphyo.ml/mmspin.min.js"></script>
    </body>
    </html>`;

    return (
        <Box sx={{ mt: 5, p: 3 }} component={Paper}>
            <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
            >
                <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                >
                    <Typography>#1 Campaign Api Key</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack
                        direction={"row"}
                        justifyContent="flex-start"
                        alignItems={"center"}
                    >
                        <Typography sx={{ width: "100%" }}>
                            <CopyBlock
                                language="jsx"
                                text={`${campaignInfo?.api_id}`}
                                codeBlock
                                theme={dracula}
                                showLineNumbers={false}
                            />
                        </Typography>
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
            >
                <AccordionSummary
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                >
                    <Typography>#2 Client Api key</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack
                        direction={"row"}
                        justifyContent="flex-start"
                        alignItems={"center"}
                    >
                        <Typography sx={{ width: "100%" }}>
                            <CopyBlock
                                language="jsx"
                                text={`${campaignInfo?.client_api_key}`}
                                codeBlock
                                theme={dracula}
                                showLineNumbers={false}
                            />
                        </Typography>
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
            >
                <AccordionSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                >
                    <Typography>#3 Script tag</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack
                        direction={"column"}
                        justifyContent="flex-start"
                        alignItems={"center"}
                        gap={2}
                    >
                        <Typography sx={{ width: "100%" }}>
                            <CopyBlock
                                language="jsx"
                                text={
                                    'CSS <link rel="stylesheet" href="https://wailwinphyo.ml/mmspin.min.css">'
                                }
                                codeBlock
                                theme={dracula}
                                showLineNumbers={false}
                            />
                        </Typography>
                        <Typography sx={{ width: "100%" }}>
                            <CopyBlock
                                language="jsx"
                                text={
                                    'JavaScript <script src="https://wailwinphyo.ml/mmspin.min.js"></script>'
                                }
                                codeBlock
                                theme={dracula}
                                showLineNumbers={false}
                            />
                        </Typography>
                        <Typography sx={{ width: "100%" }}>
                            <CopyBlock
                                language="jsx"
                                text={
                                    'JQuery <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>'
                                }
                                codeBlock
                                theme={dracula}
                                showLineNumbers={false}
                            />
                        </Typography>
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Box sx={{ mt: 5 }}>
                <Stack direction={"column"} gap={2}>
                    <Typography variant="h2">Usage</Typography>
                    <CopyBlock
                        language="jsx"
                        text={syntax}
                        codeBlock
                        theme={dracula}
                        showLineNumbers={true}
                    />
                </Stack>
            </Box>
        </Box>
    );
}
