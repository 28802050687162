import { Typography, Box } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignList } from "../../../features/company/CampaignApi";
import CampaignTable from "./CampaignTable";

export default ({ setTabIndex }) => {
    const dispatch = useDispatch();
    const { companyDetail } = useSelector((state) => state.companyDetail);
    useEffect(() => {
        dispatch(getCampaignList({ page: 0, limit: 10, id: companyDetail.id }));
    }, []);
    return (
        <Box>
            <CampaignTable setTabIndex={setTabIndex} />
        </Box>
    );
};
