import { useEffect, useState } from "react";
import { Box, Grid, Input, InputLabel } from "@mui/material";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import MkSwitch from "../../app/assets/theme/MkSwitch";
import InputFormComponent from "../../app/components/Form/InputFormComponent";
import { useSelector } from "react-redux";
import { imageApi } from "../../app/hooks";

const labelTheme = createTheme({
    components: {
        MuiFormControlLabel: {
            label: {
                fontSize: "14px",
            },
        },
    },
});

function AdminInputs({ setImageData }) {
    const theme = useTheme();

    const { selectedAdmin } = useSelector((state) => state.adminList);

    const [firstName, setFirstName] = useState(selectedAdmin.first_name ?? "");
    const [lastName, setLastName] = useState(selectedAdmin.last_name ?? "");
    const [email, setEmail] = useState(selectedAdmin.email ?? "");
    const [password, setPassword] = useState("");
    const [check, setCheck] = useState(
        selectedAdmin.active == 1 ? true : false
    );

    useEffect(() => {
        setFirstName(selectedAdmin.first_name ?? "");
        setLastName(selectedAdmin.last_name ?? "");
        setEmail(selectedAdmin.email ?? "");
        setCheck(selectedAdmin.active == 1 ? true : false);
        setImageData(
            selectedAdmin.profile ? `${imageApi}/${selectedAdmin.profile}` : "#"
        );
    }, [selectedAdmin]);

    const handleCheck = (e) => {
        setCheck(e.target.checked);
    };

    return (
        <Box sx={{ padding: "35px" }}>
            <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 12 }}
            >
                <Grid item xs={12} md={6}>
                    <InputFormComponent
                        value={firstName}
                        onChange={(e) => setFirstName(e.currentTarget.value)}
                        lable="First Name"
                        name="first_name"
                        placeholder="Enter Your First Name"
                        focus={true}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputFormComponent
                        value={lastName}
                        onChange={(e) => setLastName(e.currentTarget.value)}
                        lable="Last Name"
                        name="last_name"
                        placeholder="Enter Your Last Name"
                        required
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputFormComponent
                        value={email}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                        lable="Email"
                        name="email"
                        placeholder="Enter Your Email"
                        required
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: selectedAdmin.first_name ? "none" : "",
                    }}
                >
                    <InputFormComponent
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                        lable="Password"
                        name="password"
                        placeholder="Enter Your Password"
                    />
                </Grid>

                <Grid item xs={12} md={6} sx={{ alignSelf: "end" }}>
                    <ThemeProvider theme={labelTheme}>
                        <MkSwitch
                            switchColor={theme.palette.info.main}
                            label="Active"
                            checked={check}
                            onChange={handleCheck}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
        </Box>
    );
}

export default AdminInputs;
