import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
    Box,
    Input,
    Stack,
    Dialog,
    DialogActions,
    DialogContent,
    Avatar,
    Typography,
    Grid,
    InputLabel,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import InputFormComponent from '../../../app/components/Form/InputFormComponent';
import MkDatePicker from '../../../app/assets/theme/MkDatePicker';
import MkButton from '../../../app/assets/theme/MkButton';
// import imageLogo from "../../../app/assets/images/ImageIcon.png";

import {differenceInDays, format} from 'date-fns';
import {EditCampaignDetail} from '../../../features/company/CampaignApi';
import {changeCampaignInfo} from '../../../features/company/CampaignSlice';
import {
    useTheme,
    createTheme,
    ThemeProvider,
} from '@mui/material/styles';
import PlaceholderImage from '../../../app/assets/images/placeholderImage.png';
import PlaceholderImageMobile from '../../../app/assets/images/proplaceholder.png';
import PlaceholderQRImage from '../../../app/assets/images/qr_placeholder.png';
import {imageApi} from '../../../app/hooks';
import MkSwitch from '../../../app/assets/theme/MkSwitch';

const labelTheme = createTheme({
    components: {
        MuiFormControlLabel: {
            label: {
                fontSize: '14px',
            },
        },
    },
});

export default function CmapaignEdit({item}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const {
        campaignInfo: {
            id,
            event_title,
            description,
            logo,
            start,
            end,
            campaign_type,
            qr_caption,
            qr_logo,
            short_url_status,
            callback_url,
            company_id,
            outlet_id,
            banner_image,
            mobile_banner_image,
            disagree_url,
            banner_status,
            webview_channel,
        },
    } = useSelector((state) => state.campaignDetail);

    const [open, setOpen] = useState(false);
    const [imageData, setImageData] = useState('#');
    const [imageDataMobile, setImageDataMobile] = useState('#');
    const [title, setTitle] = useState(
        event_title ? event_title : ''
    );
    const [desc, setDesc] = useState(description ? description : '');
    const [callbackUrl, setCallbackUrl] = useState(
        callback_url ? callback_url : ''
    );
    const [disagreeUrl, setDisagreeUrl] = useState(
        disagree_url ? disagree_url : ''
    );
    const [campaignType, setCampaignType] = useState(
        campaign_type ? campaign_type : ''
    );
    const [checkShortUrl, setCheckShortUrl] =
        useState(short_url_status);
    const [bannerStatus, setBannerStatus] = useState(banner_status);
    const [qrCaption, setQrCaption] = useState(
        qr_caption ? qr_caption : ''
    );
    const [startDate, setStartDate] = useState(start ? start : '');
    const [endDate, setEndDate] = useState(end ? end : '');
    const [imageChange, setImageChange] = useState(false);
    const [imageQRData, setImageQRData] = useState('#');
    const [webviewChannel, setWebviewChannel] = useState(
        webview_channel ? webview_channel : ''
    );

    useEffect(() => {
        setTitle(event_title ? event_title : '');
        setDesc(description ? description : '');
        setCallbackUrl(callback_url ? callback_url : '');
        setDisagreeUrl(disagree_url ? disagree_url : '');
        setImageData(
            banner_image
                ? `${imageApi}/${banner_image}`
                : PlaceholderImage
        );
        setImageDataMobile(
            mobile_banner_image
                ? `${imageApi}/${mobile_banner_image}`
                : PlaceholderImageMobile
        );
        setImageQRData(
            qr_logo ? `${imageApi}/${qr_logo}` : PlaceholderQRImage
        );
        setStartDate(start ? start : '');
        setEndDate(end ? end : '');
        setCampaignType(campaign_type ? campaign_type : '');
        setQrCaption(qr_caption ? qr_caption : '');
        setImageChange(false);
        setCheckShortUrl(short_url_status);
        setBannerStatus(banner_status);
        setWebviewChannel(webview_channel);
    }, [
        event_title,
        logo,
        start,
        end,
        campaign_type,
        banner_status,
        short_url_status,
        webview_channel,
    ]);

    //imageInput change
    const imageInputChange = (e) => {
        const [file] = e.target.files;
        if (file) {
            setImageChange(true);
            setImageData(URL.createObjectURL(file));
        }
    };

    const imageQRInputChange = (e) => {
        const [file] = e.target.files;
        if (file) {
            setImageChange(true);
            setImageQRData(URL.createObjectURL(file));
        }
    };

    const imageInputChangeMobile = (e) => {
        const [file] = e.target.files;
        if (file) {
            setImageChange(true);
            setImageDataMobile(URL.createObjectURL(file));
        }
    };

    const handleClickOpen = () => {
        dispatch(changeCampaignInfo(item));
        setOpen(true);
        setImageData(
            banner_image
                ? `${imageApi}/${banner_image}`
                : PlaceholderImage
        );
        setImageDataMobile(
            mobile_banner_image
                ? `${imageApi}/${mobile_banner_image}`
                : PlaceholderImageMobile
        );
        setImageQRData(
            qr_logo ? `${imageApi}/${qr_logo}` : PlaceholderQRImage
        );
    };

    const handleClose = () => {
        setImageChange(false);
        setImageData('#');
        setImageQRData('#');
        setImageDataMobile('#');
        // setCheckShortUrl(false);
        // setBannerStatus(false);
        setOpen(false);
    };

    const handleAdd = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        formData.append('company_id', company_id);
        formData.append(
            'outlet_id',
            outlet_id == null ? 0 : outlet_id
        );
        const start = format(
            new Date(startDate),
            'yyyy-MM-dd HH:mm:ss'
        );
        const end = format(
            new Date(endDate === null ? startDate : endDate),
            'yyyy-MM-dd HH:mm:ss'
        );
        formData.append('start', start);
        formData.append('end', end);
        formData.append('short_url_status', checkShortUrl ? 1 : 0);
        formData.append('banner_status', bannerStatus ? 1 : 0);
        const currentDate = new Date(
            format(new Date(), 'yyyy-MM-dd 00:00:00')
        );
        const remainingTime =
            differenceInDays(endDate, currentDate) * 3600000;
        formData.append('remaining_time', Number(remainingTime));
        dispatch(EditCampaignDetail({data: formData, id: id}));
        handleClose();
    };

    const handleCheck = (e) => {
        setCheckShortUrl(e.target.checked);
    };

    return (
        <>
            <MkButton
                mkcolor={`linear-gradient(310deg, #2152ff, #02c6f3)`}
                size="small"
                onClick={handleClickOpen}
            >
                Edit
            </MkButton>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                component="form"
                onSubmit={handleAdd}
            >
                <DialogContent>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="baseline"
                    >
                        <Box sx={{padding: '20px 35px '}}>
                            <Typography variant="h2">
                                Edit Campaign
                            </Typography>
                        </Box>
                    </Stack>
                    <Box sx={{width: '100%', padding: '0px 35px'}}>
                        <InputLabel
                            htmlFor="mobile_banner"
                            sx={{mb: 1, fontSize: '14.5px'}}
                        >
                            Banner
                        </InputLabel>
                        <label htmlFor="contained-button-file">
                            <Input
                                accept="image/*"
                                id="contained-button-file"
                                type="file"
                                name="banner_image"
                                sx={{
                                    display: 'none',
                                }}
                                onChange={imageInputChange}
                            />
                            <Avatar
                                sx={{
                                    width: '100%',
                                    height: 200,
                                    cursor: 'pointer',
                                }}
                                variant="rounded"
                                src={imageData}
                            />
                        </label>
                    </Box>

                    <Box sx={{width: '100%', padding: '15px 35px'}}>
                        <InputLabel
                            htmlFor="mobile_banner_image"
                            sx={{mb: 1, fontSize: '14.5px'}}
                        >
                            Mobile Banner
                        </InputLabel>
                        <label htmlFor="contained-mobile-file">
                            <Input
                                accept="image/*"
                                id="contained-mobile-file"
                                type="file"
                                name="mobile_banner_image"
                                sx={{
                                    display: 'none',
                                }}
                                onChange={imageInputChangeMobile}
                            />
                            <Avatar
                                sx={{
                                    width: '100%',
                                    height: 400,
                                    cursor: 'pointer',
                                }}
                                variant="rounded"
                                src={imageDataMobile}
                            />
                        </label>
                    </Box>
                    <Box sx={{padding: '20px 35px'}}>
                        <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={3}
                        >
                            <Grid item xs={12}>
                                <InputFormComponent
                                    value={title}
                                    onChange={(e) =>
                                        setTitle(
                                            e.currentTarget.value
                                        )
                                    }
                                    lable="Event Title"
                                    name="event_title"
                                    placeholder="Enter Event Title"
                                    focus={true}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputFormComponent
                                    placeholder="Enter Description"
                                    lable="Description"
                                    name="description"
                                    multiline={true}
                                    rows={4}
                                    value={desc}
                                    onChange={(e) =>
                                        setDesc(e.currentTarget.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputFormComponent
                                    value={callbackUrl}
                                    onChange={(e) =>
                                        setCallbackUrl(
                                            e.currentTarget.value
                                        )
                                    }
                                    lable="Callback URL"
                                    name="callback_url"
                                    placeholder="Callback URL"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputFormComponent
                                    value={disagreeUrl}
                                    onChange={(e) =>
                                        setDisagreeUrl(
                                            e.currentTarget.value
                                        )
                                    }
                                    lable="Callback Fail URL"
                                    name="disagree_url"
                                    placeholder="Callback Fail URL"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel
                                    sx={{
                                        mb: 1,
                                        fontSize: '1rem',
                                    }}
                                >
                                    Start Date
                                </InputLabel>
                                <MkDatePicker
                                    fullWidth
                                    value={startDate}
                                    onChange={(newValue) => {
                                        setStartDate(newValue);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel
                                    sx={{
                                        mb: 1,
                                        fontSize: '1rem',
                                    }}
                                >
                                    End Date
                                </InputLabel>
                                <MkDatePicker
                                    fullWidth
                                    value={endDate}
                                    onChange={(newValue) => {
                                        setEndDate(newValue);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormLabel id="demo-radio-buttons-group-label">
                                    Campaign Type
                                </FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={campaignType || 0}
                                    name="campaign_type"
                                    onChange={(e) => {
                                        setCampaignType(
                                            e.target.value
                                        );
                                    }}
                                >
                                    <FormControlLabel
                                        value={0}
                                        control={<Radio />}
                                        label="Regular"
                                    />
                                    <FormControlLabel
                                        value={1}
                                        control={<Radio />}
                                        label="Auto Coupon"
                                    />
                                    <FormControlLabel
                                        value={2}
                                        control={<Radio />}
                                        label="Embedded"
                                    />
                                    <FormControlLabel
                                        value={3}
                                        control={<Radio />}
                                        label="Direct Campaign"
                                    />
                                </RadioGroup>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    display:
                                        campaignType == 1
                                            ? ''
                                            : 'none',
                                }}
                            >
                                <Box sx={{width: '100%'}}>
                                    <InputLabel
                                        htmlFor="qr_logo"
                                        sx={{
                                            mb: 1,
                                            fontSize: '14.5px',
                                        }}
                                    >
                                        QR Logo
                                    </InputLabel>
                                    <label htmlFor="contained-file">
                                        <Input
                                            accept="image/*"
                                            id="contained-file"
                                            type="file"
                                            name="qr_logo"
                                            sx={{
                                                display: 'none',
                                            }}
                                            onChange={
                                                imageQRInputChange
                                            }
                                        />
                                        <Avatar
                                            sx={{
                                                width: '30%',
                                                height: '30%',
                                                cursor: 'pointer',
                                            }}
                                            variant="rounded"
                                            src={imageQRData}
                                        />
                                    </label>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display:
                                        campaignType == 1
                                            ? ''
                                            : 'none',
                                }}
                            >
                                <InputLabel
                                    htmlFor="qr_caption"
                                    sx={{mb: 1, fontSize: '14.5px'}}
                                >
                                    QR Caption
                                </InputLabel>
                                <textarea
                                    id="qr_caption"
                                    className="css-10nlj39-MuiInputBase-input"
                                    style={{
                                        borderWidth: '2px',
                                        borderStyle: 'solid',
                                        borderColor: '#ced1d5',
                                        borderImage: 'initial',
                                        padding: '5px 8px',
                                        borderRadius: '10px',
                                    }}
                                    lable="QR Caption"
                                    name="qr_caption"
                                    placeholder="QR Caption"
                                    rows={4}
                                    value={qrCaption}
                                    onChange={(e) =>
                                        setQrCaption(
                                            e.currentTarget.value
                                        )
                                    }
                                ></textarea>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display:
                                        campaignType == 3
                                            ? ''
                                            : 'none',
                                }}
                            >
                                <InputFormComponent
                                    onChange={(e) =>
                                        setWebviewChannel(
                                            e.currentTarget.value
                                        )
                                    }
                                    value={webviewChannel}
                                    lable="Callback Webview Channel"
                                    name="webview_channel"
                                    placeholder="Callback Webview Channel"
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display:
                                        campaignType == 1 ||
                                        campaignType == 3
                                            ? ''
                                            : 'none',
                                }}
                            >
                                <InputLabel
                                    htmlFor="status"
                                    sx={{mb: 1, fontSize: '14.5px'}}
                                >
                                    Short URL Generate
                                </InputLabel>
                                <ThemeProvider theme={labelTheme}>
                                    <MkSwitch
                                        id="status"
                                        switchColor={
                                            theme.palette.info.main
                                        }
                                        checked={checkShortUrl}
                                        onChange={handleCheck}
                                        inputProps={{
                                            'aria-label':
                                                'controlled',
                                        }}
                                    />
                                </ThemeProvider>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display:
                                        campaignType == 2
                                            ? ''
                                            : 'none',
                                }}
                            >
                                <InputLabel
                                    htmlFor="status"
                                    sx={{mb: 1, fontSize: '14.5px'}}
                                >
                                    Campaign Banner Image Show Hide
                                </InputLabel>
                                <ThemeProvider theme={labelTheme}>
                                    <MkSwitch
                                        id="status"
                                        switchColor={
                                            theme.palette.info.main
                                        }
                                        checked={bannerStatus}
                                        onChange={(e) =>
                                            setBannerStatus(
                                                e.target.checked
                                            )
                                        }
                                        inputProps={{
                                            'aria-label':
                                                'controlled',
                                        }}
                                    />
                                </ThemeProvider>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions sx={{padding: '15px 35px'}}>
                    <MkButton
                        variant="outlined"
                        mkcolor="linear-gradient(310deg, #2152ff, #02c6f3)"
                        onClick={handleClose}
                    >
                        Cancel
                    </MkButton>
                    <MkButton
                        mkcolor="linear-gradient(310deg, #2152ff, #02c6f3)"
                        type="submit"
                    >
                        Update
                    </MkButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
