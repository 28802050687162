import { useState } from "react";

import { getToken } from "../features/login/LoginApi";

import {
    CssBaseline,
    FormControlLabel,
    Link,
    Box,
    Typography,
    Container,
    InputBase,
    InputLabel,
    IconButton,
    Switch,
    Avatar,
    Alert,
} from "@mui/material";
import { useDispatch } from "react-redux";
import MkButton from "../app/assets/theme/MkButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import Logo from "../app/assets/images/logo.png";
import { useSelector } from "react-redux";

function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href="https://mmspin.com/">
                MMSpin
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

export default function Login() {
    const dispatch = useDispatch();
    const { error } = useSelector((state) => state.loginInfo);

    //! psw visibility
    const [isVisible, setIsVisible] = useState(false);

    //! button submit
    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const data = {
            email: formData.get("email"),
            password: formData.get("password"),
        };
        dispatch(getToken(data));
    };

    return (
        <Container
            maxWidth="xs"
            sx={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}
        >
            <CssBaseline />

            <Box
                sx={{
                    backgroundColor: "#fff",
                    borderRadius: "1rem",
                    boxShadow: "rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem",
                    padding: "50px 30px 80px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar src={Logo} />
                {/* <Box sx={{ width: "50px" }}>
                    <img src={Logo} alt="logo" style={{ width: "100%" }} />
                </Box> */}
                <Typography component="h1" variant="h3" sx={{ mb: 2, mt: 2 }}>
                    Welcome to MMSpin
                </Typography>
                <Typography component="h1" variant="h2" sx={{ mb: 5 }}>
                    Admin Panel
                </Typography>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ mt: 1 }}
                >
                    <InputLabel
                        htmlFor="email"
                        sx={{ mb: 1, fontSize: "1rem" }}
                    >
                        Email
                    </InputLabel>
                    <InputBase
                        required
                        fullWidth
                        id="email"
                        // label="Email Address"
                        placeholder="admin@example.com"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        sx={{
                            mb: 3,
                            border: "#d2d6da solid 2px",
                            "&.Mui-focused": {
                                border: "#35d1f5 solid 2px",
                                boxShadow: `0 0 1px 2px #81e3f9de`,
                                outline: 0,
                            },
                        }}
                    />
                    <InputLabel
                        htmlFor="password"
                        sx={{ mb: 1, fontSize: "1rem" }}
                    >
                        Password
                    </InputLabel>
                    <InputBase
                        required
                        fullWidth
                        name="password"
                        // label="Password"
                        placeholder="password"
                        type={isVisible ? "text" : "password"}
                        id="password"
                        autoComplete="current-password"
                        sx={{
                            border: "#d2d6da solid 2px",
                            "&.Mui-focused": {
                                border: "#35d1f5 solid 2px",
                                boxShadow: `0 0 1px 2px #81e3f9de`,
                                outline: 0,
                            },
                        }}
                        endAdornment={
                            <IconButton
                                sx={{ p: 0 }}
                                onClick={() =>
                                    setIsVisible((prevState) => !prevState)
                                }
                            >
                                {isVisible ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        }
                    />
                    <FormControlLabel
                        control={<Switch size="small" />}
                        label="Remember me"
                        sx={{
                            paddingLeft: "10px",
                            span: { fontSize: "0.75rem" },
                        }}
                    />
                    <MkButton
                        type="submit"
                        fullWidth
                        variant="gradient"
                        mkcolor="linear-gradient(310deg, #2152ff, #02c6f3)"
                        sx={{
                            mt: 2,
                            color: "linear-gradient(310deg, #2152ff, #02c6f3)",
                        }}
                    >
                        Continue
                    </MkButton>
                    <Box
                        sx={{ marginTop: "10px", display: error ? "" : "none" }}
                    >
                        <Alert variant="filled" severity="error">
                            {error}
                        </Alert>
                    </Box>
                </Box>
            </Box>
            <Copyright sx={{ mt: 6 }} />
        </Container>
    );
}
