import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Box,
    Input,
    Stack,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Avatar,
    IconButton,
    useTheme,
    Typography,
    Grid,
    InputLabel,
    ThemeProvider,
    createTheme,
} from "@mui/material";
import { editCompanyUserDetail } from "../../../features/company/CompanyApi";
import MkButton from "../../../app/assets/theme/MkButton";
import InputFormComponent from "../../../app/components/Form/InputFormComponent";
import MkAutoComplete from "../../../app/assets/theme/MkAutoComplete";
import MkSwitch from "../../../app/assets/theme/MkSwitch";
import { companyUserDetailActions } from "../../../features/company/CompanySlice";
import { imageApi } from "../../../app/hooks";

const labelTheme = createTheme({
    components: {
        MuiFormControlLabel: {
            label: {
                fontSize: "14px",
            },
        },
    },
});

export default function EditCompanyUser({ item, roles, userRole }) {
    const { changeUserInfo } = companyUserDetailActions;
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const dispatch = useDispatch();
    const { userInfo } = useSelector((state) => state.companyUserInfo);
    const { companyDetail } = useSelector((state) => state.companyDetail);
    const [check, setCheck] = useState(userInfo.status == 1 ? true : false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [companyId, setCompanyId] = useState(null);
    const [role, setRole] = useState(userRole);
    const [imageData, setImageData] = useState(userInfo.profile);
    const [imageChange, setImageChange] = useState(false);

    const handleCheck = (e) => {
        setCheck(e.target.checked);
    };

    useEffect(() => {
        setCheck(userInfo.status == 1 ? true : false);
        setCompanyId(companyDetail.id);
        setFirstName(userInfo.first_name);
        setLastName(userInfo.last_name);
        setEmail(userInfo.email);
        setRole(userRole);
        setImageData(userInfo.profile);
    }, [userInfo, companyDetail, userRole]);

    const handleClickOpen = () => {
        dispatch(changeUserInfo(item));
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setImageChange(false);
    };

    const imageInputChange = (e) => {
        const [file] = e.target.files;
        if (file) {
            setImageChange(true);
            setImageData(URL.createObjectURL(file));
        }
    };

    const handleAdd = (e) => {
        e.preventDefault();
        const dataForm = new FormData(e.currentTarget);
        dataForm.append("status", check == true ? Number(1) : Number(0));
        dataForm.append("authority_role_id", role?.id ?? userInfo.role_id);
        dataForm.append("authority_company_id", companyId);
        if (!imageChange) {
            dataForm.delete("profile");
        }
        dispatch(
            editCompanyUserDetail({
                id: userInfo.id,
                data: dataForm,
            })
        );
        setOpen(false);
    };

    return (
        <>
            <MkButton
                mkcolor={`linear-gradient(310deg, #2152ff, #02c6f3)`}
                size="small"
                onClick={handleClickOpen}
            >
                Edit
            </MkButton>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                component="form"
                onSubmit={handleAdd}
            >
                <Box sx={{ padding: " 50px", mt: 5 }}>
                    <Box>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Typography variant="h4" mb={3}>
                                Edit Company User Info
                            </Typography>
                            <Box>
                                <label htmlFor="contained-button-file">
                                    <Input
                                        accept="image/*"
                                        id="contained-button-file"
                                        type="file"
                                        name="profile"
                                        sx={{
                                            display: "none",
                                        }}
                                        onChange={imageInputChange}
                                    />
                                    <Avatar
                                        sx={{
                                            width: 70,
                                            height: 70,
                                            cursor: "pointer",
                                        }}
                                        variant="rounded"
                                        src={
                                            !imageChange
                                                ? `${imageApi}/${imageData}`
                                                : imageData
                                        }
                                    />
                                </label>
                            </Box>
                        </Stack>
                        <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{
                                xs: 1,
                                sm: 2,
                                md: 22,
                            }}
                        >
                            <Grid item xs={12} md={6}>
                                <InputFormComponent
                                    value={firstName}
                                    onChange={(e) =>
                                        setFirstName(e.currentTarget.value)
                                    }
                                    lable="First Name"
                                    name="first_name"
                                    placeholder="Enter Your User First Name"
                                    focus={true}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <InputFormComponent
                                    value={lastName}
                                    onChange={(e) =>
                                        setLastName(e.currentTarget.value)
                                    }
                                    lable="Last Name"
                                    name="last_name"
                                    placeholder="Enter Your User Last Name"
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <InputFormComponent
                                    value={email}
                                    onChange={(e) =>
                                        setEmail(e.currentTarget.value)
                                    }
                                    lable="Email "
                                    name="email"
                                    placeholder="Enter Your User Email "
                                    required
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <InputLabel
                                    htmlFor="auto-complete"
                                    sx={{
                                        mb: 1,
                                        fontSize: "1rem",
                                    }}
                                >
                                    Role
                                </InputLabel>

                                <MkAutoComplete
                                    fullWidth={true}
                                    options={roles}
                                    placeholder="Your Role"
                                    id="auto-complete"
                                    getOptionLabel={(options) =>
                                        options?.label ?? ""
                                    }
                                    onChange={(e, newValue) =>
                                        setRole(newValue)
                                    }
                                    isOptionEqualToValue={(option, role) => {
                                        return option.label === role.label;
                                    }}
                                    value={role}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{ alignSelf: "center" }}
                            >
                                <ThemeProvider theme={labelTheme}>
                                    <MkSwitch
                                        switchColor={theme.palette.info.main}
                                        label="Active"
                                        checked={check}
                                        onChange={handleCheck}
                                        inputProps={{
                                            "aria-label": "controlled",
                                        }}
                                    />
                                </ThemeProvider>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <DialogActions sx={{ padding: "15px 35px" }}>
                    <MkButton
                        variant="outlined"
                        mkcolor="linear-gradient(310deg, #2152ff, #02c6f3)"
                        onClick={handleClose}
                    >
                        Cancel
                    </MkButton>
                    <MkButton
                        mkcolor="linear-gradient(310deg, #2152ff, #02c6f3)"
                        type="submit"
                    >
                        Update
                    </MkButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
