import {
    Avatar,
    Box,
    IconButton,
    Stack,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    useTheme,
    ThemeProvider,
    createTheme,
} from '@mui/material/styles';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import MkSwitch from '../../../app/assets/theme/MkSwitch';
import MenuPopover from '../../../app/assets/theme/MenuPopover';
import {format} from 'date-fns';
import {
    finishCampaign,
    statusCampaign,
} from '../../../features/company/CampaignApi';
import {changeCampaignInfo} from '../../../features/company/CampaignSlice';
import CmapaignEdit from './CampaignEdit';
import {formatMoney} from '../../../app/helper/formatMoney';
import {QRCode} from 'react-qrcode-logo';
import {imageApi} from '../../../app/hooks';
import {CopyAll} from '@mui/icons-material';

const labelTheme = createTheme({
    components: {
        MuiFormControlLabel: {
            label: {
                fontSize: '14px',
            },
        },
    },
});

function CampaignList({item, setTabIndex}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [checkDisable, setCheckDisable] = useState(
        item.disable === 0 ? false : true
    );
    const [checkFinish, setCheckFinish] = useState(
        item.finish === 0 ? false : true
    );

    //popover
    const [popOpen, setPopOpen] = useState(false);

    const handleCheckDisable = (e) => {
        setCheckDisable(e.target.checked);
        dispatch(statusCampaign({id: item.id}));
    };
    const handleCheckFinish = (e) => {
        setCheckFinish(e.target.checked);
        dispatch(finishCampaign({id: item.id}));
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        item;
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        setCheckDisable(item.disable === 0 ? false : true);
        setCheckFinish(item.finish === 0 ? false : true);
    }, [item]);

    // useEffect(() => {
    //     let timer1 = setTimeout(() => {
    //         dispatch(finishCampaign({ id: 22 }));
    //     }, 5000);
    //     return () => {
    //         clearTimeout(timer1);
    //     };
    // }, []);

    const handleCampaignInfo = () => {
        dispatch(changeCampaignInfo(item));
        setTabIndex(4);
    };

    const copy = (short_url) => {
        const el = document.createElement('input');
        el.value = short_url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    return (
        <TableRow>
            <TableCell>
                <Stack direction={'row'} alignItems="center">
                    <Link to={`#`}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            onClick={handleCampaignInfo}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Avatar
                                    sx={{width: 30, height: 30}}
                                    variant="rounded"
                                    src={`${imageApi}/${item.banner_image}`}
                                    alt={item.event_title}
                                />
                            </Box>
                            <Box>
                                <Typography
                                    variant="caption"
                                    component="div"
                                    sx={{
                                        fontWeight: 'bold',
                                        textAlign: 'left',
                                        color: '#67748e',
                                    }}
                                >
                                    {item.event_title}
                                </Typography>
                            </Box>
                        </Stack>
                    </Link>
                    {item.short_url_status ? (
                        <Tooltip title="Copy Link">
                            <IconButton
                                onClick={() => {
                                    copy(item.short_url);
                                }}
                            >
                                <CopyAll
                                    fontSize="small"
                                    sx={{color: '#2152ff'}}
                                />
                            </IconButton>
                        </Tooltip>
                    ) : item.campaign_type == 3 ? (
                        <Tooltip title="Copy Link">
                            <IconButton
                                onClick={() => {
                                    copy(
                                        'https://mmspin.com/direct/' +
                                            item.api_id
                                    );
                                }}
                            >
                                <CopyAll
                                    fontSize="small"
                                    sx={{color: '#2152ff'}}
                                />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                </Stack>
            </TableCell>
            <TableCell>
                <Typography variant="caption">
                    {format(new Date(item?.start), 'dd/MM/yyyy')}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption">
                    {format(new Date(item?.end), 'dd/MM/yyyy')}
                </Typography>
            </TableCell>
            <TableCell>
                {/* 0 => Regular 1 => auto coupon */}
                {item?.campaign_type === 1 ? (
                    <MenuPopover
                        open={popOpen}
                        onOpen={() => setPopOpen(true)}
                        onClose={() => {
                            setPopOpen(false);
                        }}
                    >
                        <Typography
                            variant="caption"
                            onClick={() => setPopOpen(true)}
                            style={{
                                userSelect: 'none',
                                cursor: 'pointer',
                                color: '#3535c3',
                            }}
                        >
                            Auto Coupon
                        </Typography>
                        <Box>
                            <Typography variant="caption">
                                <QRCode
                                    value={
                                        item?.short_url
                                            ? item?.short_url
                                            : `https://mmspin.com/ac/${item?.api_id}`
                                    }
                                    logoWidth={30}
                                    logoImage={`${imageApi}/${item?.qr_logo}`}
                                />
                            </Typography>
                        </Box>
                    </MenuPopover>
                ) : item?.campaign_type === 2 ? (
                    <Typography variant="caption">
                        Embedded
                    </Typography>
                ) : item?.campaign_type === 3 ? (
                    <Typography variant="caption">
                        Direct Campaign
                    </Typography>
                ) : (
                    <Typography variant="caption">Regular</Typography>
                )}
            </TableCell>
            <TableCell>
                <Typography variant="caption">
                    {formatMoney(item?.promotions_count)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption">
                    {formatMoney(item?.coupons_count)}
                </Typography>
            </TableCell>
            <TableCell align="center">
                <Typography variant="caption">
                    {item?.total_winning_amount === null
                        ? 0
                        : formatMoney(item?.total_winning_amount)}
                </Typography>
            </TableCell>
            <TableCell align="center">
                <Typography variant="caption">
                    {item?.total_spending_amount === null
                        ? 0
                        : formatMoney(item?.total_spending_amount)}
                </Typography>
            </TableCell>
            <TableCell>
                <ThemeProvider theme={labelTheme}>
                    <MkSwitch
                        switchColor={theme.palette.info.main}
                        checked={checkDisable}
                        onChange={handleCheckDisable}
                        inputProps={{
                            'aria-label': 'controlled',
                        }}
                    />
                </ThemeProvider>
            </TableCell>
            <TableCell>
                <ThemeProvider theme={labelTheme}>
                    <MkSwitch
                        switchColor={theme.palette.info.main}
                        checked={checkFinish}
                        onChange={handleCheckFinish}
                        inputProps={{
                            'aria-label': 'controlled',
                        }}
                    />
                </ThemeProvider>
            </TableCell>
            <TableCell sx={{textAlign: 'right'}}>
                <CmapaignEdit item={item} />
            </TableCell>
        </TableRow>
    );
}

export default CampaignList;
