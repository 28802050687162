import { createAsyncThunk } from "@reduxjs/toolkit";

import useFetch, { usePostForm } from "../../app/hooks";

export const getAdminList = createAsyncThunk(
    "getAdminList/getAdminList",
    async (_, { getState }) => {
        const { token } = getState().loginInfo;
        const result = await useFetch({
            url: "admins",
            method: "GET",
            token: token,
        });
        return result;
    }
);

export const addAdmin = createAsyncThunk(
    "getAdminList/addAdmin",
    async ({ data }, { getState }) => {
        const { token } = getState().loginInfo;
        const result = usePostForm({
            url: "admins",
            method: "POST",
            token: token,
            data: data,
        });
        return result;
    }
);

export const editAdmin = createAsyncThunk(
    "getAdminList/editAdmin",
    async ({ data, id }, { getState }) => {
        const { token } = getState().loginInfo;
        const result = usePostForm({
            url: `admins/${id}?_method=PUT`,
            method: "POST",
            token: token,
            data: data,
        });
        return result;
    }
);

export const statusAdmin = createAsyncThunk(
    "getAdminList/statusAdmin",
    async ({ id }, { getState }) => {
        const { token } = getState().loginInfo;
        const result = usePostForm({
            url: `admins/${id}`,
            method: "DELETE",
            token: token,
        });
        return result;
    }
);

export const passwordReset = createAsyncThunk(
    "getAdminList/passwordRest",
    async ({ id, data }, { getState }) => {
        const { token } = getState().loginInfo;
        const result = usePostForm({
            url: `resetpassword/${id}`,
            method: "POST",
            token: token,
            data: data,
        });
        return result;
    }
);
